// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDnOpgcdnG7mc4dRXnm4ud8fdH2v-MVUlo",
  authDomain: "dev-awaioapp.firebaseapp.com",
  databaseURL: "https://dev-awaioapp.firebaseio.com",
  projectId: "dev-awaioapp",
  storageBucket: "dev-awaioapp.appspot.com",
  messagingSenderId: "406248823833",
  appId: "1:406248823833:web:83ea3d89df9ba78e819e88",
  measurementId: "G-3147L3YB87",
};

module.exports = firebaseConfig;
