<template>
  <div class="d-flex justify-content-center mb-3">
    <form class="was-validated" @submit.prevent="signUp">
      <div class="mb-2">
        <input
          v-model="email"
          type="email"
          class="form-control"
          placeholder="Email"
          name="email"
          id="email"
          aria-describedby="emailHelp"
          required
        />
        <div id="emailHelp" class="form-text">
          Use your <strong>work email</strong> such as Microsoft or Google account for best experience.
        </div>
      </div>

      <div class="form-check mb-4">
        <input v-model="accepted" type="checkbox" class="form-check-input" id="accepted" name="accepted" required />
        <label class="form-check-label" for="accepted"
          >I have read and agreed to Awaio
          <a href="https://awaio.com/terms-policies/" target="_blank">general terms</a>
          and
          <a href="https://awaio.com/terms-policies/" target="_blank">privacy policy</a>
        </label>
      </div>

      <div class="d-grid col-md-4 col-lg-6 mx-auto">
        <button type="submit" class="btn btn-primary" style="color: #ffffff !important;">Sign up</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Register",
  methods: {
    signUp() {
      this.$emit("signUp", {
        email: this.email,
        accepted: this.accepted,
      });
    },
  },
  setup() {
    const email = ref("");
    const accepted = ref(false);
    return { email, accepted };
  },
};
</script>
