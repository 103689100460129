import firebase from "firebase/app";
import "firebase/functions";

import firebaseConfig from "../config/dev-config";
import config from "../config/config";

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Activate analytics
// firebase.analytics();

// Functions configuration
const functions = firebaseApp.functions(config.regions);

// Activate emulator for local development
if (process.env.NODE_ENV === "development") {
  functions.useEmulator("localhost", 5001);
}

async function callFunction(name, data) {
  let request = functions.httpsCallable(name);

  try {
    const response = await request(data);
    return response;
  } catch (e) {
    console.error(e.code, e.message);
    return null;
  }
}

async function getInvitationData(invitationCode) {
  const data = {
    type: "request",
    invitationCode: invitationCode,
  };

  return await callFunction(config.appRegistration, data);
}

async function registerUser(invitationCode, email, accepted) {
  const data = {
    type: "register",
    invitationCode: invitationCode,
    email: email,
    accepted: accepted,
  };

  return await callFunction(config.appRegistration, data);
}

export { getInvitationData, registerUser };
