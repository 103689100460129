<template>
  <div class="container gy-5 mt-5">
    <div class="row d-flex justify-content-center gy-5">
      <h3 class="text-center">Oh... we are sorry!</h3>

      <div class="d-flex justify-content-center">
        <!-- <i class="material-icons" style="font-size:100px;color:red">error</i> -->
        <font-awesome-icon icon="exclamation-circle" style="font-size:100px;color:#834767" />
      </div>

      <h3 class="text-center">Something went wrong!</h3>

      <!-- <p style="color:red">{{ message }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Failed",
  setup() {},
};
</script>
