<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-3 mb-3">
      <AwaioLogo />
    </div>

    <div v-if="loading == true">
      <div class="spinner-grow text-primary m-5" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-else>
      <div v-if="progress == 'register'">
        <div class="d-flex justify-content-center mb-3">
          <img class="rounded " alt="" src="../assets/images/early-access.jpg" style="height: 300px;" />
        </div>

        <div class="d-flex justify-content-center mb-1">
          <h1 class="text-center">{{ communityName }}</h1>
        </div>

        <Register @signUp="handelSignUp" />
      </div>

      <div v-else-if="progress == 'processing'">
        <div class="spinner-grow text-primary m-5" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-else-if="progress == 'success'">
        <Success :email="email" />
      </div>

      <div v-else-if="progress == 'failed'">
        <Failed :email="email" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { getInvitationData, registerUser } from "../firebase/firebase"; //registerUser
import AwaioLogo from "../components/AwaioLogo.vue";
import Success from "../components/Success.vue";
import Register from "../components/Register.vue";
import Failed from "../components/Failed.vue";

export default {
  name: "Welcome",
  components: {
    AwaioLogo,
    Failed,
    Register,
    Success,
  },
  created() {
    this.$nextTick(async () => {
      let response = await getInvitationData(this.invitationCode);
      if (response != null) {
        this.communityName = response.data.name;
        this.loading = false;
      } else {
        this.progress = "failed";
      }
    });
  },
  setup() {
    const loading = ref(false);
    const progress = ref("register");

    const communityName = ref("");
    const route = useRoute();
    const invitationCode = route.query.id;
    const email = ref("");

    const handelSignUp = async (data) => {
      progress.value = "processing";
      let response = await registerUser(invitationCode, data.email, data.accepted);
      if (response != null) {
        email.value = data.email;
        progress.value = "success";
      } else {
        progress.value = "failed";
      }
    };

    return { invitationCode, communityName, loading, handelSignUp, progress, email };
  },
};
</script>
