<template>
  <a href="https://awaio.com" target="_blank">
    <img alt="Visit awaio" src="../assets/images/awaio-logotype-green.svg" style="height: 50px;" />
  </a>
</template>
<script>
export default {
  name: "AwaioLogo",
};
</script>
